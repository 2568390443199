import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SlButton from "../components/button";
import ReviewSection from "../components/review-section";
import QuickActions from "../components/quickActions";
import GrayBanner from "../components/grayBanner";
import AreaMap from "../components/areaMap";
import DisplayFaqs from "../components/displayFaqs";
import CallNowBanner from "../components/callNowBanner";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import LocalBusinessSchema from "../components/Schema/localBusiness";
import { Helmet } from "react-helmet";
import LogoSchema from "../components/Schema/logo";
import Checklist from "../components/checklist";
import Certifications from "../components/certifications";
import CallIcon from "../components/call-icon";
import Layout from "../components/layout";
import { checkPropertiesForNull } from "../utils/object";
import { SafeHtmlParser } from "../components/safe-html-parser";
import { GatsbyImage } from "gatsby-plugin-image";

const IndexPage = () => {
	const data = useStaticQuery(
		graphql`
			{
				reviews {
					reviewCount
				}
				site {
					siteMetadata {
						siteUrl
					}
				}
				pageData: wpPage(slug: { eq: "home" }) {
					homePageFieldGroups {
						bannerSection {
							heading
							description
							actions {
								heading
							}
							cta1 {
								target
								title
								url
							}
							cta2 {
								target
								title
								url
							}
							image {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						ctaSection1 {
							heading
							cta1 {
								target
								title
								url
							}
							cta2 {
								target
								title
								url
							}
						}
						ctaSection2 {
							cta1 {
								target
								title
								url
							}
							cta2 {
								target
								title
								url
							}
							heading
						}
						ctaSection3 {
							heading
							cta1 {
								target
								title
								url
							}
							cta2 {
								target
								title
								url
							}
						}
						faqSection {
							heading
							faqs {
								... on WpFaq {
									id
									faqFieldsGroups {
										answer
										question
									}
								}
							}
						}
						googleMapSection {
							googleMap
							heading
						}
						headingSection {
							heading
						}
						reviewsSection1 {
							heading
						}
						reviewsSection2 {
							heading
						}
						reviewsSection3 {
							heading
						}
						reviewsSection4 {
							heading
						}
						reviewsSection4 {
							heading
						}
						serviceSection {
							serviceSectionList {
								heading
								cta {
									target
									title
									url
								}
								image {
									altText
									localFile {
										publicURL
										childImageSharp {
											original {
												height
												width
											}
											gatsbyImageData(
												formats: [AUTO, WEBP]
												quality: 100
												transformOptions: { cropFocus: CENTER, fit: CONTAIN }
												layout: CONSTRAINED
												placeholder: BLURRED
											)
										}
									}
								}
							}
						}
						textSection1 {
							discription
							heading
						}
						textSection2 {
							heading
							description
						}
						textSection3 {
							heading
							description
						}
						textSection4 {
							heading
							description
						}
						trustedSection {
							trustedList {
								starCount
								type
								link {
									target
									title
									url
								}
								image {
									altText
									localFile {
										publicURL
										childImageSharp {
											original {
												height
												width
											}
											gatsbyImageData(
												formats: [AUTO, WEBP]
												quality: 100
												transformOptions: { cropFocus: CENTER, fit: CONTAIN }
												layout: CONSTRAINED
												placeholder: BLURRED
											)
										}
									}
								}
							}
						}
						twoColumnSection1 {
							heading
							description
							image {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						twoColumnSection2 {
							heading
							description
							image {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						twoColumnSection3 {
							heading
							description
							image {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						twoColumnSection4 {
							description
							heading
							image {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					seoFieldGroups {
						localBusinessSchema
						metaDescription
						metaTitle
						openGraphDescription
						openGraphTitle
						productSchema
						image {
							altText
							publicUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
									original {
										width
										height
									}
								}
							}
						}
					}
				}
			}
		`
	);

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Seymour Locksmiths",
				item: {
					url: "https://www.seymour-locksmiths.co.uk",
					id: "https://www.seymour-locksmiths.co.uk",
				},
			},
		],
	};

	const {
		pageData: { seoFieldGroups, homePageFieldGroups },
		site,
	} = data;
	const siteUrl = site.siteMetadata.siteUrl;

	const {
		bannerSection,
		headingSection,
		twoColumnSection1,
		twoColumnSection2,
		twoColumnSection3,
		twoColumnSection4,
		trustedSection,
		ctaSection1,
		ctaSection2,
		ctaSection3,
		reviewsSection1,
		reviewsSection2,
		reviewsSection3,
		reviewsSection4,
		serviceSection,
		textSection1,
		textSection2,
		textSection3,
		textSection4,
		googleMapSection,
		faqSection,
	} = homePageFieldGroups;

	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<LocalBusinessSchema />
			{/* <LogoSchema /> */}
			<GatsbySeo
				title={seoFieldGroups?.metaTitle}
				description={seoFieldGroups?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}`,
					title: `${seoFieldGroups?.openGraphTitle}`,
					description: `${seoFieldGroups?.openGraphDescription}`,
					images: [
						{
							url: `${seoFieldGroups?.image?.localFile.publicURL}`,
							width: `${seoFieldGroups?.image?.localFile.childImageSharp.original.width}`,
							height: `${seoFieldGroups?.image?.localFile.childImageSharp.original.height}`,
							alt: `${seoFieldGroups?.image?.altText}`,
						},
					],
				}}
			/>

			<CallIcon />
			{bannerSection && !checkPropertiesForNull(bannerSection, ["heading"]) && (
				<Container as="section" fluid={true}>
					<Row>
						<Col
							xs={12}
							xl={6}
							className="mb-24 mt-5 my-xl-auto py-md-5 px-md-5 order-2 order-md-1"
						>
							<Row className="justify-content-center align-items-center">
								<Col xs={12}>
									<h1 className="lato fw-700 text-4xl title-container-spacing text-start">
										{bannerSection?.heading}
									</h1>
									<div className="text-lg title-container-spacing text-start fw-medium">
										{bannerSection?.description && (
											<SafeHtmlParser
												htmlContent={bannerSection?.description}
											/>
										)}
									</div>

									<Checklist items={bannerSection?.actions} />

									<Row>
										{bannerSection?.cta1 && bannerSection?.cta1.url && (
											<Col xs={12} md="auto">
												<SlButton
													size="small"
													className="home-button"
													href={bannerSection?.cta1.url}
													target={bannerSection?.cta1?.target ?? "_self"}
												>
													{bannerSection?.cta1?.title}
												</SlButton>
											</Col>
										)}
										{bannerSection?.cta2 && bannerSection?.cta2.url && (
											<Col xs={12} md="auto">
												<SlButton
													size="small"
													variant="outline-primary"
													as={Link}
													to={bannerSection?.cta2.url}
													target={bannerSection?.cta2?.target ?? "_self"}
												>
													{bannerSection?.cta2.title}
												</SlButton>
											</Col>
										)}
									</Row>
								</Col>
							</Row>
						</Col>
						<Col xs={12} xl={6} className="px-0 order-1 order-md-1">
							<GatsbyImage
								image={
									bannerSection.image?.localFile.childImageSharp.gatsbyImageData
								}
								alt={bannerSection.image?.altText}
								className="w-100 h-100"
							/>
						</Col>
					</Row>
				</Container>
			)}
			{headingSection &&
				!checkPropertiesForNull(headingSection, ["heading"]) && (
					<GrayBanner>
						<Row className="justify-content-center text-start text-md-center">
							<Col xs={11} sm={10}>
								<h4 className="home-banner lato">{headingSection?.heading}</h4>
							</Col>
						</Row>
					</GrayBanner>
				)}
			{twoColumnSection1 &&
				!checkPropertiesForNull(twoColumnSection1, ["heading"]) && (
					<Container fluid={true} as="section">
						<Row>
							<Col xs={12} xl={5} className="px-0 order-1 order-xl-0">
								<GatsbyImage
									image={
										twoColumnSection1.image?.localFile.childImageSharp
											.gatsbyImageData
									}
									alt={twoColumnSection1.image?.altText}
									className="w-100 h-100"
								/>
							</Col>
							<Col xs={12} xl={7} className="my-24">
								<Row className="justify-content-center">
									<Col xs={11} sm={10}>
										<h2 className="text-3xl lato fw-700 mb-3">
											{twoColumnSection1?.heading}
										</h2>
										<span className="sl-underline d-block mb-5" />
										{twoColumnSection1?.description && (
											<div className="text-lg">
												<SafeHtmlParser
													htmlContent={twoColumnSection1?.description}
												/>
											</div>
										)}
									</Col>
								</Row>
							</Col>
						</Row>
					</Container>
				)}
			<Certifications lists={trustedSection?.trustedList} />

			<ReviewSection
				fromIndex={0}
				toIndex={3}
				black
				heading={reviewsSection1?.heading}
			/>
			<QuickActions serviceSectionList={serviceSection.serviceSectionList} />
			{ctaSection1 && !checkPropertiesForNull(ctaSection1, ["heading"]) && (
				<CallNowBanner
					heading={ctaSection1?.heading}
					button={ctaSection1?.cta1}
					button2={ctaSection1?.cta2}
				/>
			)}

			{textSection1 && !checkPropertiesForNull(textSection1, ["heading"]) && (
				<Container fluid={true} className="my-24" as="section">
					<Row className="justify-content-center">
						<Col xs={11} sm={10}>
							<h2 className="text-3xl lato fw-700 mb-3">
								{textSection1?.heading}
							</h2>
							<span className="sl-underline d-block mb-5" />
							<div className="mb-20 text-lg">
								{textSection1.discription && (
									<SafeHtmlParser htmlContent={textSection1.discription} />
								)}
							</div>
						</Col>
					</Row>
				</Container>
			)}
			{textSection2 && !checkPropertiesForNull(textSection2, ["heading"]) && (
				<Container fluid={true} className="mb-24" as="section">
					<Row>
						<Col xs={12} className=" mt-below-xl-0 ">
							<Row className="justify-content-center">
								<Col xs={11} sm={10}>
									<h2 className="text-3xl lato fw-700 mb-3">
										{textSection2?.heading}
									</h2>
									<span className="sl-underline d-block mb-5" />
									<div className="mb-20 text-lg">
										{textSection2.description && (
											<SafeHtmlParser htmlContent={textSection2.description} />
										)}
									</div>
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>
			)}

			{textSection3 && !checkPropertiesForNull(textSection3, ["heading"]) && (
				<Container className="bg-black text-offwhite py-24" fluid={true}>
					<Row className="justify-content-center">
						<Col xs={11} sm={10}>
							<h2 className="text-3xl lato fw-700 mb-20 text-center">
								{textSection3?.heading}
							</h2>
							<div className="mb-20 text-lg">
								{textSection3.description && (
									<SafeHtmlParser htmlContent={textSection3.description} />
								)}
							</div>
						</Col>
					</Row>
				</Container>
			)}
			{twoColumnSection2 &&
				!checkPropertiesForNull(twoColumnSection2, ["heading"]) && (
					<Container as="section" fluid={true}>
						<Row>
							<Col xs={12} xl={6} className="px-0">
								<GatsbyImage
									image={
										twoColumnSection2.image?.localFile.childImageSharp
											.gatsbyImageData
									}
									alt={twoColumnSection2.image?.altText}
									className="w-100 h-100"
								/>
							</Col>
							<Col xs={12} xl={6} className="my-24">
								<Row className="justify-content-center">
									<Col xs={11} sm={10}>
										<h2 className="text-3xl lato fw-700 mb-3">
											{twoColumnSection2?.heading}
										</h2>
										<span className="sl-underline d-block mb-5" />
										<div className="text-lg">
											{twoColumnSection2.description && (
												<SafeHtmlParser
													htmlContent={twoColumnSection2.description}
												/>
											)}
										</div>
									</Col>
								</Row>
							</Col>
						</Row>
					</Container>
				)}
			<ReviewSection
				fromIndex={3}
				toIndex={6}
				heading={reviewsSection2?.heading}
			/>

			{ctaSection2 && !checkPropertiesForNull(ctaSection2, ["heading"]) && (
				<CallNowBanner
					heading={ctaSection2?.heading}
					button={ctaSection2?.cta1}
					button2={ctaSection2?.cta2}
				/>
			)}

			{textSection4 && !checkPropertiesForNull(textSection2, ["heading"]) && (
				<Container className="bg-black text-offwhite py-24" fluid={true}>
					<Row className="justify-content-center">
						<Col xs={11} sm={10}>
							<h2 className="text-3xl lato fw-700 mb-20 text-center">
								{textSection4?.heading}
							</h2>
							<div className="text-lg">
								{textSection4.description && (
									<SafeHtmlParser htmlContent={textSection4.description} />
								)}
							</div>
						</Col>
					</Row>
				</Container>
			)}
			{twoColumnSection3 &&
				!checkPropertiesForNull(twoColumnSection3, ["heading"]) && (
					<Container as="section" fluid={true}>
						<Row>
							<Col xs={12} xl={7} className="my-24">
								<Row className="justify-content-center">
									<Col xs={11} sm={10}>
										<h2 className="text-3xl lato fw-700 mb-3">
											{twoColumnSection3?.heading}
										</h2>
										<span className="sl-underline d-block mb-5" />
										<div className="text-lg">
											{twoColumnSection3.description && (
												<SafeHtmlParser
													htmlContent={twoColumnSection3.description}
												/>
											)}
										</div>
									</Col>
								</Row>
							</Col>
							<Col xs={12} xl={5} className="px-0">
								<GatsbyImage
									image={
										twoColumnSection3.image?.localFile.childImageSharp
											.gatsbyImageData
									}
									alt={twoColumnSection3.image?.altText}
									className="w-100 h-100"
								/>
							</Col>
						</Row>
					</Container>
				)}

			<ReviewSection
				fromIndex={6}
				toIndex={9}
				heading={reviewsSection3?.heading}
			/>
			{twoColumnSection4 &&
				!checkPropertiesForNull(twoColumnSection4, ["heading"]) && (
					<Container as="section" fluid={true}>
						<Row>
							<Col xs={12} xl={6} className="px-0">
								<GatsbyImage
									image={
										twoColumnSection4.image?.localFile.childImageSharp
											.gatsbyImageData
									}
									alt={twoColumnSection4.image?.altText}
									className="w-100 h-100"
								/>
							</Col>
							<Col xs={12} xl={6} className="my-24">
								<Row className="justify-content-center">
									<Col xs={11} sm={10}>
										<h2 className="text-3xl lato fw-700 mb-3">
											{twoColumnSection4?.heading}
										</h2>
										<span className="sl-underline d-block mb-5" />
										<div className="text-lg">
											{twoColumnSection4.description && (
												<SafeHtmlParser
													htmlContent={twoColumnSection4.description}
												/>
											)}
										</div>
									</Col>
								</Row>
							</Col>
						</Row>
					</Container>
				)}

			<ReviewSection
				fromIndex={9}
				toIndex={12}
				heading={reviewsSection4?.heading}
			/>
			{ctaSection3 && !checkPropertiesForNull(ctaSection3, ["heading"]) && (
				<CallNowBanner
					heading={ctaSection3?.heading}
					button={ctaSection3?.cta1}
					button2={ctaSection3?.cta2}
				/>
			)}
			{googleMapSection &&
				!checkPropertiesForNull(googleMapSection, ["heading"]) && (
					<AreaMap
						heading={googleMapSection?.heading}
						map={googleMapSection?.googleMap}
					/>
				)}
			{faqSection && faqSection.faqs && faqSection.faqs.length > 0 && (
				<DisplayFaqs data={faqSection.faqs} heading={faqSection?.heading} />
			)}
		</Layout>
	);
};

export default IndexPage;
